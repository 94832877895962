import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/SwipeComponent.css';

const SwipeComponent = ({ contentItems }) => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		swipeToSlide: true,
		autoplay: true,
		autoplaySpeed: 5000,
		arrows: false, // Disable navigation arrows
	};

	return (
		<div className='swipe-container'>
			<Slider {...settings}>
				{contentItems.map((item, index) => {
					const { Component, props } = item;
					return (
						<div
							key={index}
							className='content-item'
						>
							<Component {...props} />
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

export default SwipeComponent;
