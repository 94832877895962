import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import '../styles/NewUserPopup.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const RegistrationPopup = ({ handleClose }) => {

    const [inputValue, setInputValue] = useState({
        email: '',
        password: '',
        username: '',
        role: 'library',
    });

    const { email, password, username, role } = inputValue;

    const handleOnChange = (e) => {
        const { id, value } = e.target;
        setInputValue({
            ...inputValue,
            [id]: value,
        });
    };

    const handleError = (err) =>
        toast.error(err, {
            position: "bottom-left",
        });

    const handleSuccess = (msg) =>
        toast.success(msg, {
            position: "bottom-right",
        }
        );

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/v1/signup`,
                {
                    ...inputValue,
                },
                { withCredentials: true },
            );
            const { success, message } = data;
            if (success) {
                handleSuccess(message);
            } else {
                handleError(message);
            }
        } catch (error) {
            handleError(error);
        }
        setInputValue({
            ...inputValue,
            email: '',
            password: '',
            username: '',
            role: '',
        });
    };

    return ReactDOM.createPortal(
        <div className="popup">
            <div className="popup-inner">
                <h2>Register New User</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" value={email} placeholder="Enter your email" onChange={handleOnChange} autoComplete="email" />
                    <label htmlFor="email">Username</label>
                    <input type="text" id="username" value={username} placeholder="Enter your username" onChange={handleOnChange} autoComplete="username" />
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" value={password} placeholder="Enter your password" onChange={handleOnChange} />
                    <label htmlFor="role">Role</label>
                    <select className='role' id="role" value={role} onChange={handleOnChange}>
                        <option value="library">Library</option>
                        <option value="canteen">Canteen</option>
                        <option value="admin">Admin</option>
                    </select>
                    <br />

                    <button type="submit">Register</button>
                    <button type="button" onClick={handleClose}>Close</button>
                </form>
            </div>
            <ToastContainer />
        </div>,
        document.body
    );
};

export default RegistrationPopup;
