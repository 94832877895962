import React, { useEffect, useState } from 'react';
import SwipeComponent from './../components/SwipeComponent';
import ImageComponent from './../components/ImageComponent';
import HtmlComponent from './../components/HtmlComponent';
import IframeComponent from './../components/IframeComponent';
import axios from 'axios';
import io from 'socket.io-client';
import socket from '../components/socket';
import logo from '../img/H2O_LOGO_RGB_v01_BLUE.png';
import '../styles/Frontend.css';


const Frontend = () => {
	const [contentItems, setContentItems] = useState([]);
	const [isTouched, setIsTouched] = useState(false);
	const fixedIframeUrl = 'https://kiosk.kaiterra.com/1QE0Z31N8HVUXQMV'; // Fixed iframe URL
	const releaseTimeout = 10000; // Fixed timeout for releasing carousel

	/* useEffect(() => {
		// Connect to Socket.IO server
		const socket = io(process.env.REACT_APP_SERVER_URL, {
			withCredentials: true,
			extraHeaders: {
				"my-custom-header": "abcd"
			}
		});

		socket.on('connect', () => {
			setContentItems(newContentItems);
			console.log('Connected to Socket.IO server');
		});

		socket.on('message', (message) => {
			console.log(message.data);
			setContentItems(newContentItems);
			setData(prevData => {
				const newData = message.data; // Assuming message.data is the correct property

				return newData;
			});
		}); */

	useEffect(() => {

		socket.on('connect', () => {
			console.log(`Connected to Socket.IO server : ${socket.id}`);
		});

		socket.emit('GET_CAROUSEL');

		socket.on('CAROUSEL_DATA', (data) => {
			const newContentItems = data.images.map((image, index) => ({
				Component: ImageComponent,
				props: { src: image.url },
				key: index.toString(),
			}));
			// Add fixed iframe to the beginning of the carousel
			newContentItems.unshift({
				Component: IframeComponent,
				props: { src: fixedIframeUrl },
				key: 'fixed-iframe',
			});
			setContentItems(newContentItems);
		});

		socket.on('CAROUSEL_UPDATED', (images) => {
			const newContentItems = images.map((image, index) => ({
				Component: ImageComponent,
				props: { src: image.url },
				key: index.toString(),
			}));
			// Add fixed iframe to the beginning of the carousel
			newContentItems.unshift({
				Component: IframeComponent,
				props: { src: fixedIframeUrl },
				key: 'fixed-iframe',
			});
			setContentItems(newContentItems);
		});

		/* socket.on('message', (message) => {
			console.log(message.data);
			setData(prevData => {
				const newData = message.data; // Assuming message.data is the correct property

				return newData;
			});
		}); */

		socket.on('disconnect', () => {
			console.log('Disconnected from Socket.IO server');
		});
		socket.on('connect_error', (error) => {
			console.error('Connection error:', error);
		});
		return () => {
			// Cleanup: Disconnect from Socket.IO server when component unmounts
			socket.disconnect();
		};
	}, []); // Effect runs only once upon component mount

	const handleTouchStart = () => {
		setIsTouched(true);
	};

	const handleTouchEnd = debounce(() => {
		setIsTouched(false);
	}, releaseTimeout);

	return (
		<>
			<div className='frontendContainer'>

				<div className='header'>
					Header here!
				</div>
				<img className="logo"
					src={logo}  // Using the imported image
					alt='H2O'
				/>
				<div
					className='column' onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
					<SwipeComponent contentItems={contentItems} />
				</div>
			</div>
		</>
	);
};

function debounce(func, wait) {
	let timeout;
	return function (...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(this, args), wait);
	};
}

export default Frontend;
