import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useCookies } from "react-cookie";

const Login = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  console.log('Server URL:', process.env.REACT_APP_SERVER_URL); 
  const { email, password } = inputValue;
  const handleOnChange = (e) => {
    const { id, value } = e.target;
    setInputValue({
      ...inputValue,
      [id]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: "bottom-left",
    });

  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    }
    );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(inputValue);
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/login`,
        {
          ...inputValue,
        },
         
        { withCredentials: true }
      );
      console.log(data);
      const { success, message, role, user } = data;
      if (!success) {
        removeCookie("token");
        handleError(message);
        return;
      }
      if (!role) {
        removeCookie("token");
        handleError(message);
        return;
      }
      handleSuccess(message);

      setTimeout(() => {
        navigate(`/${role}`);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
    setInputValue({
      ...inputValue,
      email: "",
      password: "",
    });
  };

  return (
    <>
    <div className="form_container">
      <h2>Login Account</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            placeholder="Enter your email"
            onChange={handleOnChange}
            autoComplete="email"
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            placeholder="Enter your password"
            onChange={handleOnChange}
          />
        </div>
        <button type="submit">Submit</button>

      </form>
    </div>
    
      <ToastContainer />
    </>
  );
};

export default Login;
