import { Route, Routes } from 'react-router-dom';
import { Login, Admin, Frontend, Canteen, Library } from './pages';
import './styles/App.css';

function App() {
	return (
		<div className='App'>
			<Routes>
				<Route
					path='/admin'
					element={<Admin />}
				/>
				<Route
					path='/login'
					element={<Login />}
				/>
				<Route
					path='/'
					element={<Frontend />}
				/>
				<Route
					path='/canteen'
					element={<Canteen />}
				/>
				<Route
					path='/library'
					element={<Library />}
				/>
			</Routes>
		</div>
	);
}

export default App;
