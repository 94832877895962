import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const DragAndDropComponent = ({ items, onDragEnd, onRemove }) => {
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId='droppable'>
				{(provided) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{items.map((item, index) => (
							<Draggable
								key={item.key}
								draggableId={item.key}
								index={index}
							>
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={{
											...provided.draggableProps.style,
											margin: '0 0 8px 0',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											background: 'white',
											padding: '16px',
											border: '1px solid lightgrey',
											borderRadius: '4px',
										}}
									>
										<item.Component {...item.props} />
										<button onClick={() => onRemove(index)}>X</button>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default DragAndDropComponent;
