import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
//import "../styles/App.css"
import "../styles/Library.css"

const Library = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState("");

  useEffect(() => {
    const verifyCookie = async () => {
      
      if (!cookies.token) {
        navigate("/login");
      }
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/library`,
        {cookies,},
        { withCredentials: true }
      );
      const { success, user } = data;
      setUsername(user);
      return success
        ? (toast(`Welcome ${user} to Library!`, {
            position: "top-right",
          }))
        : (removeCookie("token"), navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);
  const Logout = () => {
    removeCookie("token");
    navigate("/login");
  };
  return (
    <>
      <div className="library_page">
        <h4>
          Welcome <span>{username}</span>
        </h4>
        <button onClick={Logout}>LOGOUT</button>
      </div>
      <div className="library_page_settings">
      Library page!
      </div>
      
      <ToastContainer />
    </>
  );
};

export default Library;
