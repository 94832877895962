import React from 'react';

const IframeComponent = ({ src }) => {
	console.log('SRC :  ', src);
	return (
		<div style={{ width: '100%', height: '100%' }}>
			<iframe
				src={src}
				style={{ width: '100%', height: '100%', border: 'none' }}
				title='frame'
				loading='lazy'
			/>
		</div>
	);
};

export default IframeComponent;
