import React, { useState } from 'react';
import Resizer from 'react-image-file-resizer';

const UploadComponent = ({ onUpload }) => {
	const [file, setFile] = useState(null);

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			Resizer.imageFileResizer(
				selectedFile,
				1200, // max width
				1200, // max height
				'WEBP',
				100,
				0,
				(uri) => {
					setFile(uri);
				},
				'base64'
			);
		}
	};

	const handleUpload = () => {
		if (file) {
			onUpload(file);
		} else {
		}
	};

	return (
		<div>
			<input
				type='file'
				onChange={handleFileChange}
			/>
			<button onClick={handleUpload}>Upload</button>
		</div>
	);
};

export default UploadComponent;
