import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import RegistrationPopup from './NewUserPopup';
import '../styles/Admin.css';
import SwipeComponent from './../components/SwipeComponent';
import ImageComponent from './../components/ImageComponent';
import HtmlComponent from './../components/HtmlComponent';
import IframeComponent from './../components/IframeComponent';
import DragAndDropComponent from './../components/DragAndDropComponent';
import UploadComponent from './../components/UploadComponent';
import io from 'socket.io-client';
import socket from '../components/socket';

const Admin = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username, setUsername] = useState('');
  const [contentItems, setContentItems] = useState([]);
  const [contentThumbItems, setContentThumbItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    const verifyCookie = async () => {
      if (!cookies.token) {
        navigate('/login');
      }
      try {
        const { data } = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v1/admin`, { cookies }, { withCredentials: true });
        const { success, user } = data;
        console.log(success, user);
        setUsername(user);
        return success
          ? toast(`Welcome ${user} to Admin page!`, { position: 'top-right' })
          : (toast(`Please log in again!`, { position: 'top-right' }), removeCookie('token'), navigate('/login'));
      } catch (error) {
        console.error('Verification error:', error);
        navigate('/login');
      }
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log(`Connected to Socket.IO server ${socket.id}`);
    });

    socket.emit('GET_CAROUSEL');

    socket.on('CAROUSEL_DATA', (data) => {
      const newContentItems = data.images.map((image, index) => ({
        Component: ImageComponent,
        props: { src: image.url, thumb: image.thumbUrl },
        key: index.toString(),
      }));
      const newContentThumbItems = data.images.map((image, index) => ({
        Component: ImageComponent,
        props: { src: image.thumbUrl }, // Use thumbnail URL for admin
        key: index.toString(),
      }));
      console.log('Admin got Carousel Data');
      setContentThumbItems(newContentThumbItems);
      setContentItems(newContentItems);
    });

    socket.on('CAROUSEL_UPDATED', (images) => {
      const newContentItems = images.map((image, index) => ({
        Component: ImageComponent,
        props: { src: image.url, thumb: image.thumbUrl },
        key: index.toString(),
      }));
      const newContentThumbItems = images.map((image, index) => ({
        Component: ImageComponent,
        props: { src: image.thumbUrl }, // Use thumbnail URL for admin
        key: index.toString(),
      }));
      console.log('Admin updated newContentItems');
      setContentThumbItems(newContentThumbItems);
      setContentItems(newContentItems);
    });
    socket.on('connect_error', (error) => {
      console.error('Connection error:', error.message);
    });

    socket.on('transport_error', (error) => {
      console.error('Transport error:', error.message);
    });

    socket.on('disconnect', (reason) => {
      console.log(`Client disconnected. Reason: ${reason}`);
    });

    return () => {
      socket.off('CAROUSEL_DATA');
      socket.off('CAROUSEL_UPDATED');
      socket.off('connect_error');
      socket.off('transport_error');
      socket.off('disconnect');
    };
  }, []);

  const handleUpload = async (url) => {
    try {
      // Send the image to the server for processing and storage
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/upload`,
        { image: url },
        { withCredentials: true, headers: { Authorization: `${cookies.token}` } }
      );
      // Check if the server processed the upload successfully
      if (response.data.success) {
        const thumbUrl = await makeThumbnail(url);
        // Update the local state with the new image
        const newItems = [...contentItems, { Component: ImageComponent, props: { src: url, thumb: thumbUrl }, key: contentItems.length.toString() }];
        setContentItems(newItems);
        const newThumbItems = [...contentThumbItems, { Component: ImageComponent, props: { src: thumbUrl }, key: contentThumbItems.length.toString() }];
        setContentThumbItems(newThumbItems);

        console.log('Images to handle  ', { images: newItems.map((item, index) => ({ url: item.props.src, thumbUrl: item.props.thumb, order: index })) });
        // Notify the server to update the carousel with the new image
        socket.emit('UPDATE_CAROUSEL', { images: newItems.map((item, index) => ({ url: item.props.src, thumbUrl: item.props.thumb, order: index })) });
      } else {
        // Log an error message if the upload failed
        console.error('Upload failed:', response.data.message);
      }
    } catch (error) {
      // Handle any errors that occurred during the upload process
      console.error('Error uploading image:', error);
    }
  };

  const makeThumbnail = (base64Image) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const MAX_WIDTH = 200;
      const MAX_HEIGHT = 200;



      img.src = base64Image;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let height = img.height;
        let width = img.width;

        // Change the resizing logic
        if (width > height) {
          if (width > MAX_WIDTH) {
            height = height * (MAX_WIDTH / width);
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = width * (MAX_HEIGHT / height);
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        let dataURL = canvas.toDataURL('image/webp', 0.8);
        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(contentItems);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setContentItems(reorderedItems);
    socket.emit('UPDATE_CAROUSEL', { images: reorderedItems.map((item, index) => ({ url: item.props.src, thumbUrl: item.props.thumb, order: index })) });
  };

  const handleRemove = async (index) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/v1/remove/${index}`, { withCredentials: true, headers: { Authorization: `${cookies.token}` } });
      if (response.data.success) {
        const updatedItems = contentItems.filter((_, i) => i !== index);
        setContentItems(updatedItems);
        socket.emit('UPDATE_CAROUSEL', { images: updatedItems.map((item, index) => ({ url: item.props.src, thumbUrl: item.props.thumb, order: index })) });
      } else {
        console.error('Remove failed:', response.data.message);
      }
    } catch (error) {
      console.error('Error removing image:', error);
    }
  };

  const Logout = () => {
    removeCookie('token');
    navigate('/login');
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className='admin_page'>
        <h4>
          Welcome <span>{username}</span>
        </h4>
        <button onClick={Logout}>LOGOUT</button>
      </div>
      <div className='admin_page_settings'>
        <button onClick={togglePopup}>Register New User</button>
        {isOpen && <RegistrationPopup handleClose={togglePopup} />}
        <UploadComponent onUpload={handleUpload} />
        <div className='column'>
          <DragAndDropComponent
            items={contentThumbItems}
            onDragEnd={handleDragEnd}
            onRemove={handleRemove}
          />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Admin;
