import React from 'react';
import './../styles/ImageComponent.css';

const ImageComponent = ({ src }) => (
	<div className='image-container'>
		<img
			src={src}
			alt='dynamic'
		/>
	</div>
);

export default ImageComponent;
